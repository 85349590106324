import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Location  from "../../components/services/location_cloud"

const Soporte = () => {
  const data = useStaticQuery(graphql`
    query Images_soporte {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Empresa Especializada en Soportes Informáticos 8/5, 12/5 y 24/7</title>
        <meta name="title" content="Empresa Especializada en Soportes Informáticos 8/5, 12/5 y 24/7"/>
        <meta name="description" content="Qualoom somos una empresa de Soporte Informático 24h todos los Días que proporcionamos asistencia técnica y soporte remoto. ¡ITe ayudamos!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/"/>
        <meta property="og:title" content="Empresa Especializada en Soportes Informáticos 8/5, 12/5 y 24/7"/>
        <meta property="og:description" content="Qualoom somos una empresa de Soporte Informático 24h todos los Días que proporcionamos asistencia técnica y soporte remoto. ¡ITe ayudamos!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/soporte-informatico/"/>
        <meta property="twitter:title" content="Empresa Especializada en Soportes Informáticos 8/5, 12/5 y 24/7"/>
        <meta property="twitter:description" content="Qualoom somos una empresa de Soporte Informático 24h todos los Días que proporcionamos asistencia técnica y soporte remoto. ¡ITe ayudamos!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/soporte-informatico/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Soporte informático 8x5, 12x5 & 24x7", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/soporte-informatico/", "name": "Soporte informático 8x5, 12x5 & 24x7"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Soporte informático para empresas 12x5 & 24x7</h1>
              <p>¿Tienes sistemas de información, soluciones de ecommerce u otro tipo de aplicaciones críticas para tu negocio? Si es así, es posible que necesites especialistas en su mantenimiento y soporte, sobre todo en horarios fuera de la atención comercial ordinaria.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Qualoom, empresa de soporte informático</h2>
          <hr></hr>
          <p><b>Qualoom te ofrece la posibilidad de disponer de un equipo de soporte en diferentes modalidades</b> para que tus sistemas de información estén monitorizados y atendidos en caso de incidencias. Ponte en contacto con nosotros para conocer más sobre este <b>servicio especializado 8x5, 12x5 o 24x7</b>.</p>
          <p>¿Tienes una empresa que utiliza medios informáticos en el día a día de la organización? ¿Tus empleados tienen que usar herramientas de colaboración, software ofimático, gestionar sus licencias, impresoras, electrónica de red, servidores on premise, etc? <b>Qualoom puede ayudarte a ofrecer el mejor soporte, mantener tus equipos, tus impresoras, aportar soluciones y asesoramiento sobre la mejor implementación para la toma de decisiones.</b></p><br/>
          <h3>Soporte técnico para Infraestructura Cloud</h3>
          <p>En Qualoom <b>ofrecemos un respaldo en todo momento para ayudarte a gestionar cualquier problema o fallo en tu infraestructura cloud/híbrida/on premise</b> y resolverlo lo antes posible. Nuestro objetivo es que nuestros clientes puedan estar tranquilos sabiendo que podrán contar con nuestro equipo de especialistas cuando más lo necesiten en cualquier ámbito: aplicaciones ofimáticas, especialistas de soporte Cloud, arquitectos de información, etc.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Cuándo ofrecemos soporte técnico a empresas?</h2><hr></hr>
          <p>Existen distintos tipos de modelo de soporte, te presentamos los más habituales:</p><br/>
          <div className="soporte-table-responsive">
            <h3><b>Soporte básico</b></h3><hr></hr>
            <ul>
              <li><b>Horario de soporte</b> (Madrid time zone)</li>
              <ul>
                <li><b>Horario de oficina ordinario</b><br/>Lunes a Jueves de 09:00 a 14:00 / 15:00 a 18:00<br/>Viernes 09:00 a 15:00</li>
                <li><b>Horario mes de agosto</b><br/>Lunes a Viernes 09:00 - 15:00</li>
              </ul>
              <li><b>Dedicación máxima mensual para consultas o incidencias</b></li><ul><li>0,5 jornadas</li></ul>
            </ul><br/>
            <h3><b>Soporte 8x5</b></h3><hr></hr>
            <ul>
              <li><b>Horario de soporte</b> (Madrid time zone)</li>
              <ul>
                <li><b>Horario de oficina ordinario</b><br/>Lunes a Jueves de 09:00 a 14:00 / 15:00 a 18:00<br/>Viernes 09:00 a 15:00</li>
                <li><b>Horario mes de agosto</b><br/>Lunes a Viernes 09:00 - 15:00</li>
              </ul>
              <li><b>Dedicación máxima mensual para consultas o incidencias</b></li><ul><li>1,5 jornadas</li></ul>
              <li><b>SLA Tiempo de respuesta</b></li><ul><li>1 hora</li></ul>
            </ul><br/>
            <h3><b>Soporte 12x5</b></h3><hr></hr>
            <ul>
              <li><b>Horario de soporte</b> (Madrid time zone)</li><ul><li>Lunes a Viernes 9.00 - 21.00</li></ul>
              <li><b>Dedicación máxima mensual para consultas o incidencias</b></li><ul><li>2 jornadas</li></ul>
              <li><b>SLA Tiempo de respuesta</b></li><ul><li>1 hora</li></ul>
            </ul><br/>
            <h3><b>Soporte 24x7</b></h3><hr></hr>
            <ul>
              <li><b>Horario de soporte</b> (Madrid time zone)</li><ul><li>Lunes a Domingo / 24 horas / 365 días al año</li></ul>
              <li><b>Dedicación máxima mensual para consultas o incidencias</b></li><ul><li>3 jornadas</li></ul>
              <li><b>SLA Tiempo de respuesta</b></li><ul><li>1 hora</li></ul>
              <li><b>Nº puestas en producción mensuales fuera de horario</b> (después de las 22:00)</li><ul><li>1 puesta en prod./mes</li></ul>
              <li><b>Días festivos</b> (Calendario oficial Madrid capital y nacionales)</li><ul><li>Cubiertos</li></ul>
            </ul><br/>
          </div>
          <div className="soporte-table">
            <div className="header-cell first-cell"></div>
            <div className="row">
              <div className="normal-cell-4 first-row"><p>Soporte básico</p></div>
              <div className="normal-cell-4 first-row"><p>Soporte 8x5</p></div>
              <div className="normal-cell-4 first-row"><p>Soporte 12x5</p></div>
              <div className="normal-cell-4 first-row"><p>Soporte 24x7</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>Horario de soporte<br/><span>(Madrid time zone)</span></p></div>
            <div className="row">
              <div className="normal-cell-4"><p><b>Horario de oficina ordinario</b><br/>Lunes a Jueves<br/>
              09:00-14:00 / 15:00 - 18:00<br/>Viernes 09:00 - 15:00<br/><br/><b>Horario mes de agosto</b><br/>
              Lunes a Viernes 09:00 - 15:00</p></div>
              <div className="normal-cell-4"><p><b>Horario de oficina ordinario</b><br/>Lunes a Jueves<br/>
              09:00-14:00 / 15:00 - 18:00<br/>Viernes 09:00 - 15:00<br/><br/><b>Horario mes de agosto</b><br/>
              Lunes a Viernes 09:00 - 15:00</p></div>
              <div className="normal-cell-4"><p>Lunes a Viernes<br/>9.00 - 21.00</p></div>
              <div className="normal-cell-4"><p>Lunes a Domingo<br/>24 horas<br/>365 días al año</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>Dedicación máxima mensual para consultas o incidencias</p></div>
            <div className="row">
              <div className="normal-cell-4"><p>0,5 jornadas</p></div>
              <div className="normal-cell-4"><p>1,5 jornadas</p></div>
              <div className="normal-cell-4"><p>2 jornadas</p></div>
              <div className="normal-cell-4"><p>3 jornadas</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>SLA Tiempo de respuesta</p></div>
            <div className="row">
              <div className="normal-cell-4"><p>-</p></div>
              <div className="normal-cell-4"><p>1 hora</p></div>
              <div className="normal-cell-4"><p>1 hora</p></div>
              <div className="normal-cell-4"><p>1 hora</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>Días festivos<br/><span>(Calendario oficial Madrid capital y nacionales)</span></p></div>
            <div className="row">
              <div className="normal-cell-4"><p>-</p></div>
              <div className="normal-cell-4"><p>-</p></div>
              <div className="normal-cell-4"><p>-</p></div>
              <div className="normal-cell-4"><p>Cubiertos</p></div>
            </div>
          </div>
          <div className="soporte-table">
            <div className="header-cell"><p>Nº puestas en producción mensuales fuera de horario<br/><span>(después de las 22:00)</span></p></div>
            <div className="row">
              <div className="normal-cell-4"><p>-</p></div>
              <div className="normal-cell-4"><p>-</p></div>
              <div className="normal-cell-4"><p>-</p></div>
              <div className="normal-cell-4"><p>1 puesta en producción / mes</p></div>
            </div>
          </div><br/>
          <h3>¿Por qué es necesario contar con una empresa de soporte técnico?</h3>
          <p>No todos sabemos de todo y no todos sabemos al nivel más adecuado. Por esta razón, y al ponerte en manos de especialistas en tecnología con una contrastada experiencia en ecosistemas de alto rendimiento y servicios críticos, te permitirán estar tranquilo a la hora de resolver problemas de rendimiento, problemas sistémicos o incluso operaciones.</p>
        </div>
      </div>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Dónde ofrecemos nuestro servicio de soporte técnico?</h2><hr></hr>
          <p>Todos nuestros servicios, así como los modelos de soporte se ofrecen de manera remota a través de la utilización de herramientas de comunicación y colaboración de primer nivel. <b>Herramientas como Microsoft Teams, Slack,  Skype y otras, junto con el correo electrónico y teléfonos directos</b>, permitirán contar con nuestro equipo como si estuvieran a tu lado.</p><Location></Location>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Soporte